// Your variable overrides
//$theme-colors: (
//  "primary": #00ffff,
//  "danger": #fa8072
//);

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

.react-select__control {
  min-width: 30vw;
}

.form_group_recaptcha > div > div {
  display: flex;
  place-content: center;
}

.dynamic-form-in-modal {
  border-width: 0px;
  .card-header, .card-body, .card-footer {
    border-width: 0px;
  }
  margin-bottom: 0px;
}

.tbody-scroll {
  display: block;
  overflow-y: scroll;
}

.scrollable-table-tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display:none;
}
